<template>
	<main>

		<component v-for="(s, id) in sections" :key="id" :id="s.alias" :is="s.variables.component" :class="s.variables.component.toLowerCase()" :s="s" />

	</main>
</template>


<script>
import { mapGetters } from 'vuex';

import Contact from '@/components/Contact.vue';
import Portfolio from '@/components/Portfolio.vue';
import Splash from '@/components/Splash.vue';
import Text from '@/components/Text.vue';

var vm;

export default {
	components: {
		Contact,
		Portfolio,
		Splash,
		Text,
	},


	computed: {
		...mapGetters([
			'pages',
		]),


		sections()
		{
			let sections = [];

			for (let i = 0; i < vm.pages.length; i++)
			{
				let s = vm.pages[i];
				sections[s.menuindex] = s;
			}

			return sections;
		},
	},


	created()
	{
		vm = this;
	},


	mounted()
	{
		vm.$emit('finalize');
	},
}
</script>

import { createRouter, createWebHistory } from "vue-router";

// import Category from "@/views/Category.vue";
// import Contact from "@/views/Contact.vue";
// import DefaultPage from "@/views/DefaultPage.vue";
import Home from "@/views/Home.vue";
// import PageBuilder from "@/views/PageBuilder.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },

    // {
    //     path: "/contact",
    //     name: "Contact",
    //     component: Contact,
    // },
	
    // {
    //     path: "/diensten/:alias",
    //     name: "Category",
    //     component: Category,
    // },

	// {
	// 	path: "/informatie/:alias",
	// 	name: "PageBuilder",
	// 	component: PageBuilder,
	// },
	
    // {
    //     path: "/:alias",
    //     name: "DefaultPage",
    //     component: DefaultPage,
    // },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;

<template>

	<section :style="background">

		<div class="content">
			<blockquote v-html="s.content" class="reveal from-right" />

			<a v-if="next !== false" :href="next" class="button black fal fa-file-alt reveal from-right">
				Lees meer over
				{{ siteName }}
			</a>

			<a href="https://nl.linkedin.com/in/dennis-marinissen-8162a45a/en" target="_blank" class="button fab fa-linkedin-in reveal from-right">
				{{ siteName }}
				op LinkedIn
			</a>
		</div>

	</section>

</template>


<script>
import { mapGetters } from 'vuex';

var vm;

export default {
	props: {
		s: Object,
	},

	computed: {
		...mapGetters([
			'base',
			'siteName',
			'structure',
		]),


		background()
		{
			let img = vm.s.variables.image;

			return img.length === 0 ? '' : "background-image: url('" + vm.base + '/' + img + "');";
		},


		next()
		{
			let n = false;

			for (let i = 0; i < vm.structure.length; i++)
			{
				let item = vm.structure[i];

				if (item.alias == vm.s.alias && i < (vm.structure.length - 1))
				{
					n = '#' + vm.structure[i + 1].alias;
				}
			}

			return n;
		},
	},


	created()
	{
		vm = this;
	},
}
</script>

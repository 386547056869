import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default function pageData() {
	const route = useRoute();
	const store = useStore();

	const pages = computed(() => {
		return store.getters.pages;
	});

	const page = computed(() => {
		// Get the current path
		let path = route.path.length > 1 ? route.path + '/' : route.path;

		if (['image', 'product'].indexOf(route.name) >= 0)
		{
			path =	path.split('/')
					.slice(0, -2)
					.join('/') + '/';
		}

		if (path.substr(-2) == '//') path = path.substr(0, path.length - 1);
		
		let page = pages.value.find(p => path == '/' + p.path.replace(p.http_host, ''));
		
		// Fallback search for the home page
		if (typeof(page) == 'undefined' && path == '/')
		{
			page = pages.value.find(() => path == '/');
		}

		if (page)
		{
			// Create some additional paramters
			page.hasImage = Object.keys(page.variables).indexOf('image') >= 0;
			page.title = page.longtitle.length > 0 ? page.longtitle : page.pagetitle;
		}
		
		return page ? page : {};
	});

	return {
		page,
	};
}
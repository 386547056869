<template>
<footer>
	
	<span>
		&copy;
		{{ year }}
		{{ siteName }}
	</span>
	
	<span>
		Realisatie:

		<a href="https://dizero.nl" target="_blank" class="dizero">
			Dizero
		</a>
	</span>

</footer>
</template>


<script>
import { mapGetters } from 'vuex';

export default {
	data()
	{
		return {
			year: new Date().getFullYear(),
		};
	},


	computed: {
		...mapGetters([
			'siteName',
		]),
	},
}
</script>
<template>
	<section class="portfolio">

		<div class="images reveal from-bottom">

			<div v-for="(img, id) in images" :key="['img', id].join('-')" @click="() => showImg(id)" :id="['img', id].join('-')" :class="classes(id)" >
				<figure :data-bg="bgImage(img)" />
			</div>

		</div>


		<vue-easy-lightbox
			moveDisabled
			:visible="visible"
			:imgs="images"
			:index="index"
			@hide="handleHide"
	    />

	</section>
</template>


<script>
import { mapGetters } from 'vuex';

import VueEasyLightbox from 'vue-easy-lightbox'

var vm;

export default {
	components: {
		VueEasyLightbox,
	},


	props: {
		s: Object,
	},


	data()
	{
		return {
			visible: false,
			index: 0,
		};
	},


	computed: {
		...mapGetters([
			'base',
		]),


		images()
		{
			let images = [];

			for (let i = 0; i < vm.s.sections.length; i++)
			{
				let img = vm.s.sections[i];

				images.push({
					src: vm.base + img.variables.image,
					title: img.pagetitle,
				});
			}

			return images;
		},
	},


	methods: {
		bgImage(img)
		{
			return img.src.length === 0 ? '' : "background-image: url('" + img.src + "');";
		},


		classes(id)
		{
			let cl = ['image'];

			if (id === vm.index)
			{
				cl.push('current');
			}

			return cl.join(' ');
		},


		handleHide()
		{
			this.visible = false;
		},


		showImg(index)
		{
			this.index = index;
			this.visible = true;
		},
	},


	created()
	{
		vm = this;
	},
}
</script>

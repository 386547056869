<template>
<header>
	<img src="img/logo.svg" width="150" height="48" :alt="siteName" />

	<nav>
		<a v-for="(item, id) in structure" :key="['item', id].join('-')" :href="'#' + item.alias" @click.prevent="slideTo">
			<i :class="item.icon"></i>

			<span>
				{{ item.name }}
			</span>
		</a>
	</nav>
</header>
</template>


<script>
import { mapGetters } from 'vuex';

export default {
	computed: {
		...mapGetters([
			'siteName',
			'structure',
		]),
	},


	methods: {
		slideTo(e)
        {
            let target = e.target;

            while (target.tagName.toLowerCase() != 'a')
            {
                target = target.parentNode;
            }

            let el = document.querySelector(target.attributes.href.value),
                header = document.querySelector('header'),
                y = header.offsetTop == 0 ? el.offsetTop - header.clientHeight : el.offsetTop;

            // smooth scroll to element and align it at the bottom
            window.scrollTo({top: y, behavior: 'smooth'});
        },
	},
}
</script>

<template>
	<section class="contact">

		<div class="content reveal from-bottom">
			<h2>
				{{ title }}
			</h2>

			<div v-if="s.content.length > 0" v-html="s.content" />
		</div>


		<div v-if="s.sections.length > 0" class="container">

			<div v-for="(section, id) in s.sections" :key="['section', id].join('-')" :class="classes(id)" v-html="section.content" />

		</div>

	</section>
</template>


<script>
var vm;

export default {
	props: {
		s: Object,
	},


	computed: {
		title()
		{
			return vm.s.longtitle.length > 0 ? vm.s.longtitle : vm.s.pagetitle;
		},
	},


	methods: {
		classes(id)
		{
			return [
				'cell',
				'reveal',
				id % 2 ? 'from-right' : 'from-left',
			].join(' ');
		},
	},


	created()
	{
		vm = this;
	},
}
</script>

<template>

	<section class="text">

		<div class="section reveal from-bottom">

			<h2>
				{{ s.longtitle }}
			</h2>

			<div v-html="s.content" />

		</div>


		<div :class="['section', state].join(' ')" v-for="(sub, id) in s.sections" :key="['sub', id].join('-')">

			<div v-if="sub.variables.hasOwnProperty('image')" class="image">
				<img width="100" height="100" :data-img="base + sub.variables.image" :alt="sub.pagetitle" />
			</div>

			<div class="content" v-html="sub.content" />

			<div v-if="sub.variables.hasOwnProperty('extraContent')" class="content" v-html="sub.variables.extraContent" />

		</div>


		<a href="#" :class="buttonClasses" @click.prevent="show = !show">
			Lees
			{{ show ? 'minder' : 'meer' }}
		</a>

	</section>

</template>

<script>
import { mapGetters } from 'vuex';

var vm;

export default {
	props: {
		s: Object,
	},


	data()
	{
		return {
			show: false,
		};
	},


	computed: {
		...mapGetters([
			'base',
		]),


		buttonClasses()
		{
			return [
				'button',
				'reveal',
				'from-bottom',
				'fas',
				vm.show ? 'fa-minus' : 'fa-plus',
			].join(' ');
		},


		state()
		{
			return vm.show ? 'show' : 'hide';
		},
	},


	created()
	{
		vm = this;
	},
}
</script>
